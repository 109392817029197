import {
  Box,
  ChakraProvider,
  ColorModeScript,
  DarkMode,
  LightMode,
} from '@chakra-ui/react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { dark } from './themes/theme';
import './themes/style.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <ChakraProvider theme={dark}>
      <StrictMode>
        <QueryClientProvider client={queryClient}>
          <LightMode>
            {/* <Box bg="bg.light" color="dark"> */}
            <App />
            {/* </Box> */}
          </LightMode>
        </QueryClientProvider>
      </StrictMode>
    </ChakraProvider>
  </Provider>
);

