import React, { useState, useEffect } from 'react';
import WeightModal from '../../components/WeightModal';
import CountUp from 'react-countup';
import { Card } from '@nextui-org/react';
import scale from './scale.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Weights = () => {
  const [todayTentList, setTodayTentList] = useState([]);
  const [tentList, setTentList] = useState([]);
  const [tent, setTent] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpenWeight, setIsOpenWeight] = useState(false);

  const handleCloseWeight = () => {
    setIsOpenWeight(false);
  };

  // Tent capacities mapping
  const tentCapacities = {
    '5': 4409,
    '7': 4144,
    '26': 3400,
    '27': 4610,
    '28': 2143,
    '30': 4760,
    '31': 4880,
    '32': 5360,
    '33': 2688,
    '34': 4000,
    '35': 4968,
    '36': 3322,
  };

  // Function to format the date in 'YYYY-MM-DD' format
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleDayChange = (date) => {
    setSelectedDate(date);
    setTentList(sortTents(todayTentList)); // Set tentList based on todayTentList
  };

  const sortTents = (tents) => {
    const order = [
      '5',
      '7',
      '26',
      '27',
      '28',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
    ];
    return tents.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://baitguests2.com:8000/api/camera/food_weight/summary/${formatDate(selectedDate)}`,
          {
            headers: {
              Authorization: 'Token bc9d81174fc895b0618460b8ffc9350f806883df',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Fetched data:', data);

        if (!Array.isArray(data)) {
          throw new Error('Data is not an array');
        }

        const filterTents = (tents) =>
          tents.filter((tent) =>
            [
              '5',
              '7',
              '26',
              '27',
              '28',
              '30',
              '31',
              '32',
              '33',
              '34',
              '35',
              '36',
            ].includes(tent.name)
          );

        const mapTentData = (tent) => ({
          id: tent.pk,
          name: tent.name,
          is_arafa: false,
          capacity: tentCapacities[tent.name] || 'Unavailable', // Use capacity from mapping
          date: formatDate(selectedDate), // Include selectedDate here
          meals: {
            breakfast: {
              name: 'الفطور',
              acceptedMeals: tent.summary.breakfast_today_total_accepted,
              rejectedMeals: tent.summary.breakfast_today_total_rejected,
              averageWeight: tent.summary.breakfast_today_total_avg_weight,
            },
            lunch: {
              name: 'الغداء',
              acceptedMeals: tent.summary.lunch_today_total_accepted,
              rejectedMeals: tent.summary.lunch_today_total_rejected,
              averageWeight: tent.summary.lunch_today_total_avg_weight,
            },
            dinner: {
              name: 'العشاء',
              acceptedMeals: tent.summary.dinner_today_total_accepted,
              rejectedMeals: tent.summary.dinner_today_total_rejected,
              averageWeight: tent.summary.dinner_today_total_avg_weight,
            },
          },
        });

        const todayTents = sortTents(filterTents(data.map(mapTentData)));
        setTodayTentList(todayTents);
        setTentList(todayTents);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedDate]); // Fetch data whenever selectedDate changes

  if (!tentList.length) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 px-5">
      <div className="w-full">
        <h1 className="w-full text-center text-4xl pt-4 text-[#d68e35]">
          وزن الوجبه
        </h1>
      </div>
      <div className="sort-container flex justify-end mb-5">
        <div className="bg-white px-3 rounded">
          <DatePicker
            selected={selectedDate}
            onChange={handleDayChange}
            dateFormat="yyyy-MM-dd"
            className="form-control"
          />
        </div>
      </div>
      <div className="tentList-container items-end flex flex-row-reverse justify-center flex-wrap gap-3">
        {isOpenWeight && (
          <WeightModal
            tent={tent}
            orderList={[]}
            day={formatDate(selectedDate)}
            isOpen={isOpenWeight}
            handleClose={handleCloseWeight}
          />
        )}

        {tentList
          .filter((tent) => !tent.is_arafa)
          .map((tent) => (
            <Card
              key={tent.id}
              isPressable
              isHoverable
              variant="bordered"
              css={{ mw: '170px' }}
              onClick={() => {
                setTent(tent);
                setIsOpenWeight(true);
              }}
            >
              <div className="w-full justify-between text-blue-800 items-end">
                <div className="flex gap-1 items-center justify-end">
                  <p className="text-[20px]">{tent.name} مركز</p>
                  <img src={scale} className="h-[30px] m-[10px]" alt="" />
                </div>
                <div className="bg-white p-3 rounded cursor-pointer">
                  <div className="text-[16px]">
                    <div className="flex flex-row-reverse justify-between items-center">
                      <p className="text-blue-800 m-[3px]">أعداد الحجاج</p>
                      <p className="font-semibold text-black m-[3px]">
                        <CountUp
                          start={0}
                          end={tent.capacity}
                          duration={2}
                          separator=","
                        />
                      </p>
                    </div>
                    <div className="mb-5">
                      <p className="flex flex-row-reverse justify-between m-[3px]">
                        <span className="text-blue-800">عدد الوجبات</span>
                        <span className="text-black">
                          <CountUp
                            start={0}
                            end={
                              tent.meals.breakfast.acceptedMeals +
                              tent.meals.lunch.acceptedMeals +
                              tent.meals.dinner.acceptedMeals
                            }
                            duration={2}
                            separator=","
                          />
                        </span>
                      </p>
                      <p className="flex justify-between flex-row-reverse m-[3px]">
                        <span className="text-blue-800">متوسط الوزن</span>
                        <span className="text-black">
                          <CountUp
                            start={0}
                            end={(() => {
                              const weights = [
                                tent.meals.breakfast.averageWeight,
                                tent.meals.lunch.averageWeight,
                                tent.meals.dinner.averageWeight,
                              ];
                              const nonZeroWeights = weights.filter(
                                (weight) => weight !== null && weight !== 0
                              );
                              const count = nonZeroWeights.length;
                              const sum = nonZeroWeights.reduce(
                                (acc, weight) => acc + weight,
                                0
                              );
                              return count > 0 ? sum / count : 0;
                            })()}
                            duration={2}
                            separator=","
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
      </div>
    </div>
  );
};

export default Weights;
