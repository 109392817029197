/*
import React, { useEffect, useState } from 'react';
import { tentListAPI } from '../../lib/apis';
import TentInfoCard from '../../components/tentInfoCard';
import { useGetTentListQuery } from '../../redux/api';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Shared/Header';

const Cameras = () => {
  const data = useSelector(state => state?.global?.TentList);
  // console.log(data);


  return (
    <div className="min-h-screen bg-gray-100 p-5">
      <Header title={'حصر اعداد الحجاج'} />
      <div className="tentList-container justify-center flex flex-row-reverse flex-wrap gap-3 box-border">
        {data ? data?.filter(tent => !tent?.is_arafa)?.map(tent => (
          <TentInfoCard key={tent.pk} tent={tent}></TentInfoCard>
        )) : <p>Loading</p>}
      </div>
    </div>
  );
};

export default Cameras;
*/
import React from 'react';
import { useSelector } from 'react-redux';
import TentInfoCard from '../../components/tentInfoCard';
import Header from '../../components/Shared/Header';
import CountUp from 'react-countup';
import { background } from '@chakra-ui/react';

const Cameras = () => {
  // Retrieve the tent list data from the Redux store
  const data = useSelector(state => state?.global?.TentList);

  // Calculate the total capacity of the tents, filtering out tents in Arafa
  const totalCapacity = data
    ?.filter(tent => !tent?.is_arafa)
    .reduce((sum, tent) => sum + (tent.capacity || 0), 0);

  // Calculate the total staying and total available
  let totalStaying = 0;
  let totalAvailable = 0;
  data
    ?.filter(tent => !tent?.is_arafa)
    .forEach(tent => {
      // Use the adjust value from the tent data directly
      const staying =
        tent.realtime_data.staying >= 0
          ? Math.ceil(tent.realtime_data.staying)
          : 0;
      totalStaying += staying;

      const available = tent?.capacity - staying;
      totalAvailable += available;
    });
  const customStyle = {
    background: '#d4edda',
    maxWidth: '250px',
  };
  return (
    <div className="min-h-screen bg-gray-100 p-5">
      {/* Render the header with the title */}
      <Header title={'حصر اعداد الحجاج'} />
      <div className="tentList-container justify-center flex flex-row-reverse flex-wrap gap-3 box-border">
        <div
          style={customStyle}
          className="nextui-c-BDLTQ nextui-c-jMTBsW nextui-c-gulvcB nextui-c-BDLTQ-kUADSW-variant-bordered nextui-c-BDLTQ-fmlsco-borderWeight-light nextui-c-BDLTQ-cuwTXc-disableAnimation-false nextui-c-BDLTQ-iZxJaC-isPressable-true nextui-c-BDLTQ-ijfKVcX-css"
        >
          {/* Display the total values */}
          <div className="w-full justify-between text-blue-800 items-end">
            <div className="flex gap-1 items-center justify-end">
              <p className="text-[20px] ">Total Overview</p>
              <img
                src="/path/to/your/image.jpg"
                className="h-[25px] m-[10px]"
                alt=""
              />
            </div>

            <div className="flex justify-between items-center flex-row-reverse">
              <p className="text-base text-blue-800 ml-1">أعداد الحجاج</p>
              <p className="text-lg text-black font-semibold">
                <CountUp
                  start={0}
                  end={totalCapacity}
                  duration={2}
                  separator=","
                />
              </p>
            </div>

            <div className="flex justify-between items-center flex-row-reverse">
              <p className="text-base text-blue-800 ml-1">‫المتواجدون</p>
              <p className="text-lg text-black font-semibold">
                <CountUp
                  start={0}
                  end={totalStaying}
                  duration={2}
                  separator=","
                />
              </p>
            </div>

            <div className="flex justify-between items-center flex-row-reverse">
              <p className="text-base text-blue-800 ml-1">المتبقي</p>
              <p className="text-lg text-black font-semibold">
                <CountUp
                  start={0}
                  end={totalAvailable}
                  duration={2}
                  separator=","
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Render the list of tents */}
      <div className="tentList-container justify-center flex flex-row-reverse flex-wrap gap-3 box-border">
        {data ? (
          data
            .filter(tent => !tent?.is_arafa)
            .map(tent => (
              // Render each tent info card
              <TentInfoCard key={tent.pk} tent={tent}></TentInfoCard>
            ))
        ) : (
          <p>Loading</p>
        )}
      </div>
    </div>
  );
};

export default Cameras;
