import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyBzNHB_SEgQXI8lNSW1veym_Vl3WpgHLnc",
    authDomain: "hajjweb-2fe03.firebaseapp.com",
    projectId: "hajjweb-2fe03",
    storageBucket: "hajjweb-2fe03.appspot.com",
    messagingSenderId: "296675735776",
    appId: "1:296675735776:web:8901666f0888c6beca3885"
  };
  

firebase.initializeApp(firebaseConfig);

var database = firebase.database();

export default database;