// import ReactLoading from 'react-loading';
import React, { useEffect, useState } from 'react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import thermometer from '../assets/icons/thermometer.png';
import humidity from '../assets/icons/humidity.png';
import CountUp from 'react-countup';
import close from '../assets/icons/close.png';
import { Table } from '@nextui-org/react';
import database from './config.js';

const model_styles = {
  position: 'fixed',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1500,
  borderRadius: '12px',
  height: 'auto',
  width: 'auto',
};

const overlay_style = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: '100%',
  width: '100%',
  backgroundColor: '#D9D9D980',
  zIndex: 1000,
};

const WeightModal = ({
  isOpenWeight,
  handleCloseWeight,
  tent,
  orderList,
  day,
}) => {
  // const [isOpenWeight, setIsOpen] = useState(false);
  //   const handleCloseWeight = () => {
  //     setIsOpen(false);
  //   };

  const [totalAccepted, setTotalAccepted] = useState(0);
  const [bAccepted, setbAccepted] = useState(0);
  const [lAccepted, setlAccepted] = useState(0);
  const [dAccepted, setdAccepted] = useState(0);
  const [bRejected, setbRejected] = useState(0);
  const [lRejected, setlRejected] = useState(0);
  const [dRejected, setdRejected] = useState(0);
  const [bTotalWt, setbTotalWt] = useState(0);
  const [lTotalWt, setlTotalWt] = useState(0);
  const [dTotalWt, setdTotalWt] = useState(0);
  const [bAvgWt, setbAvgWt] = useState(0);
  const [lAvgWt, setlAvgWt] = useState(0);
  const [dAvgWt, setdAvgWt] = useState(0);
  useEffect(() => {
    let avgOrder = orderList?.filter(
      order => parseInt(order.DeviceNum) === parseInt(tent?.name)
    );

    if (avgOrder != undefined) {
      avgOrder.forEach(element => {
        //  avgWeight = Number(element.Weight);
        const weightsArray = [];
        //   console.log('updateTime', element.UpdateTime.slice(11,13));
        //if(element.DeviceNum===12){
        //avgWeight=353

        database
          .ref('offices/' + element.DeviceNum)
          .orderByChild('UpdateTime')
          .once('value', snapshot => {
            const weightsArray = [];
            console.log('isExist', snapshot.exists());
            if (!snapshot.exists()) {
            } else {
              const data = snapshot.val();
              let officeList = data;
              officeList.weights.forEach(t => {
                if (t.weight != undefined && t.UpdateTime != undefined) {
                }
                const arr = {
                  weight: t.weight,
                  UpdateTime: t.UpdateTime,
                };
                weightsArray.push(arr);
              });
              console.log('currentWeightsArray', weightsArray);

              const uniqueUpdateTimes = new Set();
              const filteredWeightsArray = [];

              weightsArray.forEach(weight => {
                if (weight.UpdateTime != undefined) {
                  if (!uniqueUpdateTimes.has(weight.UpdateTime)) {
                    uniqueUpdateTimes.add(weight.UpdateTime);
                    filteredWeightsArray.push(weight);
                  }
                }
              });

              console.log('Filtered weights array:', filteredWeightsArray);

              let dateWiseOrder = filteredWeightsArray.filter(
                dworder => dworder.UpdateTime.slice(0, 10) === day
              );
              console.log('datawiseorder', dateWiseOrder, avgOrder, day);

              let breakfast = dateWiseOrder?.filter(
                breakfast =>
                  '01' <= breakfast.UpdateTime.slice(11, 13) &&
                  '11' >= breakfast.UpdateTime.slice(11, 13)
              );
              console.log('breakfast', breakfast);

              let launch = dateWiseOrder?.filter(
                launch =>
                  '11' <= launch.UpdateTime.slice(11, 13) &&
                  '18' >= launch.UpdateTime.slice(11, 13)
              );
              console.log('launch', launch);

              let dinner = dateWiseOrder?.filter(
                dinner =>
                  '18' <= dinner.UpdateTime.slice(11, 13) &&
                  '23' >= dinner.UpdateTime.slice(11, 13)
              );
              console.log('dinner', dinner);

              let bFoodAccepted = breakfast?.filter(
                order => order.weight >= 200
              );
              var bAcceptedd = bFoodAccepted.length;
              setbAccepted(bAcceptedd);
              let lFoodAccepted = launch?.filter(order => order.weight >= 400);
              var lAcceptedd = lFoodAccepted.length;
              setlAccepted(lAcceptedd);
              let dFoodAccepted = dinner?.filter(order => order.weight >= 400);

              console.log('dFood', dFoodAccepted.length);
              var dAcceptedd = dFoodAccepted.length;
              setdAccepted(dAcceptedd);

              console.log('bAccepted', bAcceptedd);
              console.log('lAccepted', lAcceptedd);
              console.log('dAccepted', dAcceptedd);

              var bRejectedF = breakfast?.filter(
                order => order.weight < 200
              ).length;
              var lRejectedF = launch?.filter(
                order => order.weight < 300
              ).length;
              var dRejectedF = dinner?.filter(
                order => order.weight < 300
              ).length;

              setbRejected(bRejectedF);
              setlRejected(lRejectedF);
              setdRejected(dRejectedF);

              console.log('bRejected', bRejectedF);
              console.log('lRejected', lRejectedF);
              console.log('dRejected', dRejectedF);
              var sumBAcc = 0;
              bFoodAccepted.forEach(element => {
                sumBAcc = sumBAcc + element.weight;
              });
              console.log('sumBAcc', sumBAcc);
              setbTotalWt(sumBAcc);

              console.log('bTotalWt', bTotalWt);
              var sumLAcc = 0;
              lFoodAccepted.forEach(element => {
                sumLAcc = sumLAcc + element.weight;
              });
              console.log('sumLAcc', sumLAcc);
              setlTotalWt(sumLAcc);
              console.log('lTotalWt', lTotalWt);

              var sumDAcc = 0;
              dFoodAccepted.forEach(element => {
                sumDAcc = sumDAcc + element.weight;
              });
              console.log('sumDAcc', sumDAcc);

              setdTotalWt(sumDAcc);
              var dAvgWtFood = dAcceptedd > 0 ? sumDAcc / dAcceptedd : 0;
              setdAvgWt(dAvgWtFood);
              console.log('dAvgWt', dAvgWtFood);
              console.log('dTotalWt', dTotalWt);
              var bAvgWtFood = bAcceptedd > 0 ? sumBAcc / bAcceptedd : 0;
              setbAvgWt(bAvgWtFood);
              console.log('bAvgWt', bAvgWtFood);
              var lAvgWtFood = lAcceptedd > 0 ? sumLAcc / lAcceptedd : 0;
              setlAvgWt(lAvgWtFood);
              console.log('lAvgWt', lAvgWtFood);

              var totalAcceptedd = bAcceptedd + lAcceptedd + dAcceptedd;
              setTotalAccepted(totalAcceptedd);
              console.log('totalAccepted', totalAcceptedd);
            }
          });

        //     }
      });
    }
  }, [orderList, tent]);

  return (
    <>
      {isOpenWeight ? (
        <>
          <div onClick={handleCloseWeight} style={overlay_style} />
          {/* <div className="flex justify-center items-center"> */}
          <div
            style={model_styles}
            className="flex md:px-[300px] px-[150px] relative justify-center items-center"
          >
            {/* Rest of the content */}
            <div className="absolute w-full h-full justify-center items-center">
              {/* <SensorInfo siteData={siteData} /> */}
              <div className="bg-white p-3 relative rounded-3xl">
                <div
                  className="absolute top-0 right-0 p-2 cursor-pointer"
                  onClick={handleCloseWeight}
                >
                  <img src={close} className="h-[30px] m-[10px]" alt="" />
                </div>
                <h4 className="text-center mb-3 text-blue-800 text-[18px]">
                  {tent.name} مركز
                </h4>
                <h4 className="text-center mb-3 text-blue-800 ">
                  {tent.capacity} : اعداد الحجاج
                </h4>
                <Table
                  lined
                  aria-label="."
                  css={{
                    height: 'auto',
                    minWidth: '100%',
                  }}
                >
                  <Table.Header>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوجبات المرفوضة{' '}
                    </Table.Column>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوجبات المقبولة
                    </Table.Column>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوزن المتوسط{' '}
                    </Table.Column>
                    <Table.Column></Table.Column>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row key="1">
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={bRejected}
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={bAccepted}
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={bAvgWt}
                          duration={2}
                          separator=","
                        />
                        gm
                      </Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        الفطور
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="2">
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={lRejected}
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={lAccepted}
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={lAvgWt}
                          duration={2}
                          separator=","
                        />
                        gm
                      </Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        الغداء
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="3">
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={dRejected}
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={dAccepted}
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={dAvgWt}
                          duration={2}
                          separator=","
                        />
                        gm
                      </Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        العشاء
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key="4">
                      <Table.Cell>
                        <CountUp start={0} end={0} duration={2} separator="," />{' '}
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            totalAccepted >= 0 ? Math.ceil(totalAccepted) : 0
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell
                        css={{
                          color: '#1d4ed8',
                        }}
                      >
                        المجموع
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
          {/* </div> */}
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default WeightModal;
