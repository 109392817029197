import React, { useEffect, useState } from 'react';
import AdminLayout from '../Layouts/AdminLayout';
import LocationSelector from '../../components/SelectLocation';
import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { RegisterSiteAPI, cameraListAPI, tentListAPI } from '../../lib/apis';
import { Button, Input, Spacer, Table } from '@nextui-org/react';
export default function SiteRegistration() {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [tentList, setTentList] = useState([]);
  const [cameraList, setCameraList] = useState([]);
  const [pageLoad, setPageLoad] = useState(false);
  const [searchBySerial, setSearchBySerial] = useState('');

  const [siteReg, setsiteReg] = useState({
    name: '',
    capacity: '',
    lat: '',
    long: '',
  });

  useEffect(() => {
    if (selectedLocation) {
      setsiteReg({
        ...siteReg,
        lat: selectedLocation.lat,
        long: selectedLocation.lng,
      });
    }
  }, [selectedLocation]);

  const fetchData = async () => {
    await tentListAPI(setTentList);
    await cameraListAPI(setCameraList);
  };

  useEffect(() => {
    let interval;
    fetchData();
    const startFetchingData = () => {
      interval = setInterval(fetchData, 15000);
    };

    const stopFetchingData = () => {
      clearInterval(interval);
    };

    startFetchingData();

    return () => {
      stopFetchingData();
    };
  }, []);

  const HandleSiteReg = () => {
    setSubmitLoading(true);
    RegisterSiteAPI(siteReg, setsiteReg, setSubmitLoading);
  };

  return (
    <AdminLayout>
      {
        // tentList && tentList.length > 0 && console.log(tentList[0]?.capacity)
      }
      <Flex w="full" mb={3} justifyContent={'center'} mt={20}>
        <VStack spacing={4} w={'40%'}>
          <HStack justifyContent={'space-between'} w="100%">
            <Text w="30%">Name</Text>
            <Input
              type="text"
              placeholder="Name of the Site"
              value={siteReg.name}
              onChange={e => {
                setsiteReg({ ...siteReg, name: e.target.value });
              }}
            />
          </HStack>
          <HStack justifyContent={'space-between'} w="100%">
            <Text w="30%">Capacity</Text>
            <Input
              type="text"
              placeholder="Capacity of the Site"
              value={siteReg.capacity}
              onChange={e => {
                setsiteReg({ ...siteReg, capacity: e.target.value });
              }}
            />
          </HStack>
        </VStack>
      </Flex>
      <div className="p-10">
        <LocationSelector
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
        />
      </div>
      <VStack my={3} mr={10} justifyContent={'center'} alignItems={'end'}>
        <div className='grid w-full place-content-center'>
          <Button
            className="text-white bg-blue-600"
            w={'10%'}
            colorScheme="blue"
            onClick={HandleSiteReg}
            isLoading={submitLoading}
          >
            Submit
          </Button>
        </div>
        <Spacer y={2.5} />
        <Box
          width="90%"
          // maxHeight="500px"
          overflowY="auto"
          // className="scrolling"
        >
          <Table
            bordered
            shadow={true}
            color="secondary"
            aria-label="Example pagination  table"
            css={{
              height: '30vh',
              minWidth: '100vh',
            }}
            selectionMode="single"
          >
            <Table.Header>
              <Table.Column>NAME</Table.Column>
              <Table.Column>CAPACITY</Table.Column>
              <Table.Column>LAT</Table.Column>
              <Table.Column>LONG</Table.Column>
            </Table.Header>
            <Table.Body>
              {tentList?.map((el, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{el.name}</Table.Cell>
                  <Table.Cell>{el.capacity}</Table.Cell>
                  <Table.Cell>{el.lat}</Table.Cell>
                  <Table.Cell>{el.long}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Pagination
              shadow
              noMargin
              align="center"
              rowsPerPage={6}
              onPageChange={page => console.log({ page })}
            />
          </Table>
        </Box>

        {/* <Box
          width="90%"
          // maxHeight="500px"
          overflowY="auto"
        // className="scrolling"
        >
          <Table variant="striped" colorScheme="teal">
            <Thead>
              <Tr>
                <Th textAlign="center">Name</Th>
                <Th textAlign="center"> Capacity </Th>
                <Th textAlign="center"> Lat </Th>
                <Th textAlign="center"> Long </Th>
              </Tr>
            </Thead>
            <Tbody class>
              {tentList &&
                // tentList?.sort((a, b) =>
                //   a.capacity === b.capacity ? 0 : a.capacity ? -1 : 1
                // )
                tentList?.map(
                  (el, i) =>
                    // el.sn.includes(searchBySerial) && (
                    <Tr key={Math.random()}>
                      <Td textAlign="center">{el.name}</Td>
                      <Td textAlign="center">{el.capacity}</Td>
                      <Td textAlign="center"> {el.lat}</Td>
                      <Td textAlign="center"> {el.long}</Td>
                    </Tr>
                  // )
                )}
            </Tbody>
          </Table>
        </Box> */}
      </VStack>

      {/* <Text>{JSON.stringify(selectedLocation)}</Text> */}
    </AdminLayout>
  );
}
