import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import PublicRoute from './PublicRoute.jsx';
import PrivateRoute from './PrivetRoute.jsx';
import Dashboard from '../Pages/Dashboard.jsx';
import ReactDOMServer from 'react-dom/server';

import Error from '../Pages/Error.jsx';
import Login from '../Pages/Login.jsx';
import UserRegistration from '../Pages/Admin/UserRegistration.jsx';
import CameraRegistration from '../Pages/Admin/CameraRegistration.jsx';
import SiteRegistration from '../Pages/Admin/SiteRegistration.jsx';
import SensorRegistration from '../Pages/Admin/SensorRegistration.jsx';
import Scrape from '../Pages/Scrape.jsx';
import Weights from '../Pages/Weights/Weights.jsx';
import Sensor from '../Pages/Sensor.jsx';
import Cameras from '../Pages/Cameras/Cameras.jsx';
import Main from '../Pages/Layouts/Main.jsx';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import AirQuality from '../components/AirQuality.jsx';
import Arafa from '../Pages/Arafa/Arafa.jsx';
import { getCookie } from '../lib/Cookies.js';
import FormFood from '../components/FormFood.jsx';

export default function MainRoutes() {
  // const user = JSON.parse(getCookie('user'));
  // console.log('cookies user', user?.data?.sensor_update_permission);

  const theme = createTheme({
    myColors: {
      blue: '#0066b2',
      yellow: '#ba8537',
    },
    typography: {
      fontFamily: ['Noto Sans Arabic', 'sans-serif'].join(','),
      always: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      },
    },
  });
  return (

    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route element={<PublicRoute />}>
          <Route path="/" element={<Login />} />

            {/* <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/auth" element={<EmailAndPass />} /> */}
          </Route>

          <Route element={<PrivateRoute />}>
            {/* <Route path="/Home" element={<Main />}>
            <Route path="/cameras" element={<Cameras />} />
            <Route path="/weights" element={<Weights />} />
            <Route path="/sensor" element={<Sensor />} />
            <Route>
          </Route> */}
            <Route path="/" element={<Main />}>
            <Route path="" element={<Dashboard />} />
            <Route path="home" element={<Dashboard />} />
            <Route path="cameras" element={<Cameras />} />
            <Route path="weights" element={<Weights />} />
            <Route path="sensor" element={<Sensor />} />
            <Route path="arafa" element={<Arafa />} />
              {/* <Route path="dev" element={<FormFood />} /> */}
            </Route>

            <Route
              path="/adminsite/userregistration"
              element={<UserRegistration />}
            />
            <Route
              path="/adminsite/cameraregistration"
              element={<CameraRegistration />}
            />
            <Route
              path="/adminsite/siteregistration"
              element={<SiteRegistration />}
            />
            <Route
              path="/adminsite/sensorregistration"
              element={<SensorRegistration />}
            />
            <Route path="/scrape" element={<Scrape />} />

            <Route path="airquality" element={<AirQuality />}></Route>

            {/* <Route path="/aboutyou" element={<AboutYou />} /> */}

            {/* <Route path="/" element={<Dashboard />} />
          <Route path="/Routine" element={<Routine />} />
          <Route path="/Links" element={<Link />} />
          <Route path="/Notices" element={<Notice />} />
          <Route path="/Peoples" element={<People />} /> */}
            {/* <Route path="/CR-Options" element={<CROption />}>
            <Route path="routineManagement" element={<RoutineManagement />} />
            <Route path="scheduleChanges" element={<ScheduleChanges />} />
            <Route path="verification" element={<h1>Verification</h1>} />
            <Route
              path="blockAndUnblock"
              element={<BlockAndUnblock/> }
            />
            <Route path="addAndRemoveCr" element={<AddAndRemoveCr />} />
            <Route path="addAndRemoveLink" element={<AddAndRemoveLink />} />
            <Route path="addAndRemoveNotice" element={<AddAndRemoveNotice />} />
          </Route> */}
          </Route>
          {/* <Route element={<AIrQualityRoute/>}>
          </Route> */}

        {/* <AIrQualityRoute
          exact
          path="/airquality"
          component={AirQuality}
          allowedPath="/airquality"
          isAuthenticated={permission}
        /> */}
          <Route path="*" element={<Error />} />

        {/* Other public routes */}
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

// export const MainRoutes = createBrowserRouter([
//   {
//     path: '/',
//     element: <PublicRoute><Login></Login></PublicRoute>
//   }
// ])
