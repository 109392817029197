import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie } from '../lib/Cookies';

import {
  setTentList,
  setCameraList,
  setOrderList,
  setSensorList,
  setTentData,
} from './global';

const AuthToken = getCookie('user')
  ? JSON.parse(getCookie('user'))?.data.token
  : '';

const reduxApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://baitguests2.com:8000/',
    prepareHeaders: headers => {
      if (AuthToken) {
        headers.set('Authorization', `Token ${AuthToken}`);
      }
      return headers;
    },
  }),
  reducerPath: 'reduxApi',
  endpoints: builder => ({
    getTentList: builder.query({
      query: () => {
        return '/api/camera/tent_list';
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const data = await queryFulfilled;
          dispatch(setTentList(data?.data));
          dispatch(reduxApi.endpoints.getTentData.initiate(data?.data));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getCameraList: builder.query({
      query: () => {
        return '/api/camera/camera_list';
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const data = await queryFulfilled;
          dispatch(setCameraList(data.data));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getSensorList: builder.query({
      query: () => {
        return 'api/camera/sensor_list';
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const data = await queryFulfilled;
          dispatch(setSensorList(data.data));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    getTentData: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const prom = await Promise.all(
          _arg?.map(tent =>
            fetchWithBQ(`/api/camera/tent_realtime_data/${tent.pk}`)
          )
        );
        const data = prom?.map((tent, index) => {
          return {
            ...tent.data,
            pk: _arg[index].pk,
            name: _arg[index].name,
          };
        });
        _queryApi.dispatch(setTentData(data));
        return data;
      },
    }),
    getOrderList: builder.query({
      query: () => {
        return 'https://pet-force-server-pulok-thedeveloper.vercel.app/cors-proxy';
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          const data = await queryFulfilled;
          console.log(' getOrderList response', data.data);
          dispatch(setOrderList(data.data));
        } catch (error) {
          console.log(' getOrderList Error', error);
        }
      },
    }),
  }),
});

export default reduxApi;
export const {
  useGetTentListQuery,
  useGetCameraListQuery,
  useGetSensorListQuery,
  useGetTentDataQuery,
  useGetOrderListQuery,
} = reduxApi;
