import { Flex, HStack, Text, VStack, extendTheme } from '@chakra-ui/react';
import {
  Table,
  Input,
  Spacer,
  Button,
  Card,
  Grid,
  Link,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import {
  RegisterSensorAPI,
  UpdateCameraAPI,
  UpdateSensorAPI,
  sensorListAPI,
  tentListAPI,
} from '../../lib/apis';
import AdminLayout from '../Layouts/AdminLayout';
import { MenuItem, Select } from '@mui/material';
import { useGetTentListQuery, useGetSensorListQuery } from '../../redux/api';

export default function SensorRegistration() {
  // const [tentList, setTentList] = useState([]);
  // const [sensorList, setSensorList] = useState([]);
  // const [submodal, setSubmodal] = useState();
  const [pageLoad, setPageLoad] = useState(false);

  const [sensorRegData, setSensorRegData] = useState({
    sn: '',
    tent: '',
  });

  const [searchByDeviceNumber, setSearchBvDeViceNumber] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const { data: tentList } = useGetTentListQuery(undefined, {
    pollingInterval: 60000,
  });
  const { data: sensorList } = useGetSensorListQuery(undefined, {
    pollingInterval: 60000,
  });
  console.log('my tentlist', tentList);
  console.log('my sensor data', sensorList);
  // const fetchData = async () => {
  //   await tentListAPI(setTentList);
  //   await sensorListAPI(setSensorList);
  //   // await submodal(setSensorList[0]);
  // };

  // useEffect(() => {
  //   let interval;

  //   const startFetchingData = () => {
  //     fetchData();
  //     interval = setInterval(fetchData, 15000);
  //   };

  //   const stopFetchingData = () => {
  //     clearInterval(interval);
  //   };

  //   startFetchingData();

  //   return () => {
  //     stopFetchingData();
  //   };
  // }, []);

  const HandleSensorReg = () => {
    if (!sensorRegData.sn) {
      alert('Put the serial number');
    } else if (!sensorRegData.tent) {
      alert('Select a site');
    } else {
      setSubmitLoading(true);
      RegisterSensorAPI(sensorRegData, setSensorRegData, setSubmitLoading);
    }
  };

  // const [roles, setRoles] = useState(tentList.map(tent => tent.pk));

  // const handleRoleChange = (event, index) => {
  //   const newRoles = [...roles];
  //   newRoles[index] = event.target.value;
  //   setRoles(newRoles);
  // };
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Calculate pagination indexes
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sensorList?.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const breakpoints = {
    sm: '320px',
    md: '768px',
    lg: '1024px',
    xl: '1200px',
    '2xl': '1536px',
  };

  const theme = extendTheme({ breakpoints });
  console.log('tentlist', tentList);

  return (
    <div>
      <AdminLayout>
        {pageLoad && (
          <Flex
            w="100%"
            h="100%"
            zIndex={'2'}
            position={'absolute'}
            bg="#757575"
            opacity={0.4}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Button
              variant={'outline'}
              isLoading
              color="white"
              border="none"
            ></Button>
          </Flex>
        )}
        <Flex
          w="full"
          h="full"
          justifyContent={'space-between'}
          flexFlow={{ base: 'column', lg: 'row' }}
          my={20}
        >
          <VStack spacing={4} w={'100%'} mx="auto" p={10}>
            <HStack justifyContent={'space-between'} w="100%">
              <Text w="30%">Device Number</Text>
              <Input
                type="text"
                placeholder="Enter device number(s)"
                value={sensorRegData.sn}
                onChange={e => {
                  setSensorRegData({ ...sensorRegData, sn: e.target.value });
                }}
              />
            </HStack>
            <HStack justifyContent={'space-between'} w="100%">
              <Text w="30%">Site</Text>
              <select
                className="bg-transparent border-2 py-2 px-4 rounded-md"
                variant="filled"
                w=""
                onChange={e => {
                  setSensorRegData({ ...sensorRegData, tent: e.target.value });
                }}
                value={sensorRegData?.tent}
              >
                <option value={''}>Select Site</option>
                {tentList?.map((el, i) => {
                  return (
                    <option value={el.pk}>
                      {el.name}--{el.location}{' '}
                    </option>
                  );
                })}
              </select>
            </HStack>
            <Button
              className="bg-blue-600 w-full text-lg font-light py-6"
              colorScheme="blue"
              onClick={HandleSensorReg}
              isLoading={submitLoading}
            >
              Submit
            </Button>
          </VStack>

          <Flex w="full" flexFlow={'column'} alignItems="center" p={10}>
            <Input
              width="100%"
              type="text"
              placeholder="Search By Device Number"
              value={searchByDeviceNumber}
              onChange={e => {
                setSearchBvDeViceNumber(e.target.value);
              }}
            />
            <Spacer y={2.5} />
            <div className="w-full">
              <table className="bg-white w-full border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b">Name</th>
                    <th className="py-2 px-4 border-b">Tent</th>
                    {/* <th className="py-2 px-4 border-b">Role</th> */}
                    <th className="py-2 px-4 border-b">Last Update</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((item, i) => (
                    <tr className=" text-center" key={i}>
                      <td className="py-2 px-4 border-b">{item?.sn}</td>
                      {/* <td className="py-2 px-4 border-b">{item?.pk}</td> */}
                      <td className="py-2 px-4 border-b">
                        <select
                          className="bg-transparent border-2 p-2 rounded-lg"
                          onChange={e => {
                            setPageLoad(true);
                            UpdateCameraAPI(
                              item.pk,
                              item.sn,
                              e.target.value,
                              setPageLoad
                              // fetchData
                            );
                            // console.log(el.pk,el.sn,el.tent,e.target.value)
                          }}
                          value={item?.tent?.id}
                        >
                          <option value={null}>Select Site</option>
                          {tentList?.map((e, i) => {
                            return (
                              <option value={e.pk}>
                                {e.name}--{e.location}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td
                        className={`${
                          item.is_active ? 'text-green-600' : 'text-red-600'
                        } px-2 py-4 border-b`}
                      >
                        {item?.is_active ? 'Online' : 'Offline'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-center mt-4">
                <nav className="inline-flex">
                  <ul className="flex items-center">
                    {Array.from(
                      { length: Math.ceil(sensorList?.length / itemsPerPage) },
                      (_, index) => {
                        if (
                          index === 0 || // Always include the first page
                          index ===
                            Math.ceil(sensorList?.length / itemsPerPage) - 1 || // Always include the last page
                          (index >= currentPage - 2 && index <= currentPage + 2) // Include pages within the current page +/- 2 range
                        ) {
                          return (
                            <li key={index}>
                              <button
                                className={`px-3 py-2 mx-1 rounded-full ${
                                  currentPage === index + 1
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-200 text-gray-600'
                                }`}
                                onClick={() => paginate(index + 1)}
                              >
                                {index + 1}
                              </button>
                            </li>
                          );
                        } else if (
                          // Insert a "dot dot" for pages not included in the current range
                          index === currentPage - 3 ||
                          index === currentPage + 3
                        ) {
                          return (
                            <li key={index}>
                              <span className="px-3 py-2 mx-1">...</span>
                            </li>
                          );
                        } else {
                          return null; // Skip pages not included in the pagination range
                        }
                      }
                    )}
                  </ul>
                </nav>
              </div>
            </div>

            {/* <Box
          pt="30px"
            width="90%"
            // maxHeight="500px"
            height="62vh"
            overflowY="auto"
            className="scrolling"
          >
            <Table className='w-1/2' variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th textAlign="center">Device Number</Th>
                  <Th textAlign="center"> Tent </Th>
                  <Th textAlign="center"> Status </Th>
                </Tr>
              </Thead>
              <Tbody class>
                {sensorList
                  ?.sort((a, b) =>
                    a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1
                  )
                  ?.map(
                    (el, i) =>
                      el?.sn.includes(searchByDeviceNumber) && (
                        <Tr key={Math.random()}>
                          <Td textAlign="center">{el.sn}</Td>
                          <Td textAlign="center">
                            <Select
                              variant="filled"
                              w="40%"
                              mx="auto"
                              onChange={e => {
                                setPageLoad(true);
                                UpdateSensorAPI(
                                  el.pk,
                                  el.sn,
                                  e.target.value,
                                  setPageLoad,
                                  fetchData
                                );
                                // console.log(el.pk,el.sn,el.tent,e.target.value)
                              }}
                              value={el.tent}
                            >
                              <option value={null}>Select Site</option>
                              {tentList.map((e, i) => {
                                return (
                                  <option value={e.pk}>
                                    {e.name}--{e.location}
                                  </option>
                                );
                              })}
                            </Select>
                          </Td>
                          <Td
                            textAlign="center"
                            color={el.is_active ? 'green' : 'red'}
                            fontWeight={'semibold'}
                          >
                            {el.is_active ? 'Online' : 'Offline'}
                          </Td>
                        </Tr>
                      )
                  )}
              </Tbody>
            </Table>
          </Box> */}
          </Flex>
        </Flex>
      </AdminLayout>
    </div>
  );
}
