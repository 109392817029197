export default function getEnvironmentData(data) {
  return data?.reduce(
    (temp, data) => {
      return {
        maximumTemp: Math.max(temp.maximumTemp, data.tempareture / 10),
        minimumTemp: Math.min(temp.minimumTemp, data.tempareture / 10),
        maxHumadity: Math.max(temp.maxHumadity, data.humidity),
        minHumadity: Math.min(temp.minHumadity, data.humidity),
        totalTemp: temp.totalTemp + data.tempareture / 10,
        totalHumidity: temp.totalHumidity + data.humidity,
      };
    },
    {
      maximumTemp: -1000000000000,
      minimumTemp: 1000000000000,
      maxHumadity: -1000000000000,
      minHumadity: 1000000000000,
      totalTemp: 0,
      totalHumidity: 0,
    }
  );
}
