import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { tentDataAPI } from '../lib/apis';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSite } from '../redux/global';
import camera from '../assets/icons/cctv-camera.png';
import { Card } from '@nextui-org/react';

const TentInfoCard = ({ tent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const siteData =
    useSelector(
      state => state?.global?.TentData?.filter(data => data.pk === tent.pk)[0]
    ) || {};
  // console.log(siteData);

  const available =
    tent?.realtime_data?.staying >= 0
      ? tent?.capacity - tent?.realtime_data?.staying
      : tent?.capacity;
  const sign = available > 0 ? '' : '+';

  const handleClick = tent => {
    const jsonData = JSON.stringify(tent);
    localStorage.setItem('camera', jsonData);
    dispatch(setSite(tent));
  };

  return (
    <Card
      isPressable
      isHoverable
      variant="bordered"
      css={{ mw: '250px', p: '$10', bg: sign == '+' ? '#fff1cc' : '#dfd' }}
      onClick={() => {
        handleClick(tent);
        navigate('/home');
      }}
    >
      <div className="w-full  justify-between text-blue-800 items-end">
        <div className="flex gap-1 items-center justify-end ">
          <p className="text-[20px] ">{tent?.name} مركز</p>
          <img src={camera} className="h-[25px] m-[10px]" alt="" />
        </div>
        <div className="flex justify-between items-center flex-row-reverse">
          <p className="text-base text-blue-800 ml-1">أعداد الحجاج</p>
          <p className="text-lg text-black font-semibold">
            <CountUp
              start={0}
              end={tent?.capacity ? tent.capacity : 'Unavailable'}
              duration={2}
              separator=","
            />
          </p>
        </div>
        <div className="flex justify-between items-center flex-row-reverse">
          <p className="text-base text-blue-800 ml-1">‫المتواجدون</p>
          <p className="text-lg text-black font-semibold">
            <CountUp
              start={0}
              end={
                tent?.realtime_data ? tent.realtime_data.staying : 'Unavailable'
              }
              duration={2}
              separator=","
            />
          </p>
        </div>

        <div className="flex justify-between items-center flex-row-reverse">
          <p className="text-base text-blue-800 ml-1">المتبقي</p>
          <p className="text-lg text-black font-semibold">
            {' '}
            {/* {`${sign}`} */}
            <CountUp start={0} end={available} duration={2} separator="," />
          </p>
        </div>
      </div>
    </Card>
  );
};

export default TentInfoCard;
