  import { configureStore } from "@reduxjs/toolkit";
import reduxApi from "./api";
import globalSlice from "./global";

const store = configureStore({
    reducer: {
        [reduxApi.reducerPath]: reduxApi.reducer,
        [globalSlice.name]: globalSlice.reducer,
    },
    middleware: (getDefaultMiddlewares) => {
        return getDefaultMiddlewares().concat(reduxApi.middleware);
    },
    devTools: true,
});

export default store;