/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { orderListAPI, tentDataAPI } from '../lib/apis';
import WeightModal from './WeightModal';

import database from './config.js';


const OrderCard = ({ tent, orderList, day, isTitleShow = true, fontSize = '18px'}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [avgTFoodWeight, setAvgTFoodWeight] = useState(0);
  const [totalAccepted, setTotalAccepted] = useState(0);
  let  avgWeight = 0;


  useEffect(() => {
 var avgFoodWeight=0;
 let avgOrder = orderList
    ?.filter(order => parseInt(order.DeviceNum) === parseInt(tent?.name)); 

   

    if( avgOrder!=undefined)
      {
          avgOrder.forEach(element => {
              avgWeight = Number(element.Weight);
              console.log('updateTime', element.UpdateTime.slice(11));
              
  
          //   if(element.DeviceNum===74){
              database.ref('offices/' + element.DeviceNum).once('value').then(snapshot => {
                console.log('isExistBase', snapshot.exists());
                const weightsArray =[];
                if (!snapshot.exists()) {
                  if(avgWeight !=undefined && element.UpdateTime!=undefined){

                    const arr =
                    {
                        weight:avgWeight,
                        UpdateTime:element.UpdateTime,
                    };

                    weightsArray.push(arr);
                    console.log('firstweightsArray', weightsArray);
                    const dataToInsert = { officeId: element.DeviceNum,
                                           weights: weightsArray,
                    };
                    database.ref('offices/' + element.DeviceNum).set(dataToInsert);
                  }
                   
                
                } else {



                    const data = snapshot .val ();
                    let officeList = data
                            officeList.weights.forEach(t => {
                              if(t.weight!=undefined && t.UpdateTime!=undefined){
                             
                              }
                                const arr =
                                {
                                    weight:t.weight,
                                    UpdateTime:t.UpdateTime,  
                                };
                                weightsArray.push(arr);
                            });

                    console.log('currentExistingWeightsArray', weightsArray);
                    if(avgWeight !=undefined && element.UpdateTime!=undefined){
                
                  
                  
    const arr =
                    {
                        weight:avgWeight,
                        UpdateTime:element.UpdateTime,
                    };

                    weightsArray.push(arr);
                    console.log('updatedCurrentWeightsArray', weightsArray);
                    const dataToInsert = { officeId: element.DeviceNum,
                                           weights: weightsArray,
                    };
                    database.ref('offices/' + element.DeviceNum).set(dataToInsert);}
                   }

            });


             database.ref('offices/' + element.DeviceNum).orderByChild('UpdateTime').once('value', (snapshot) => {
              const weightsArray =[];
              console.log('isExist', snapshot.exists());
              if (!snapshot.exists()) {
                    
             } else
              {
         
         
            
              const data = snapshot .val ();
              let officeList = data
                      officeList.weights.forEach(t => {
                        if(t.weight!=undefined && t.UpdateTime!=undefined){
                      }

                          const arr =
                          {
                              weight:t.weight,
                              UpdateTime:t.UpdateTime,  
                          };
                          weightsArray.push(arr);
                      });

              
              console.log('currentAllWeightsArray', weightsArray);


              const uniqueUpdateTimes = new Set();
              const filteredWeightsArray = [];
              
              weightsArray.forEach((weight) => {
                if(weight.UpdateTime!=undefined){
                  if (!uniqueUpdateTimes.has(weight.UpdateTime)) {
                    uniqueUpdateTimes.add(weight.UpdateTime);
                    filteredWeightsArray.push(weight);
                  }
                }
               
              });
              
              console.log('Filtered weights array:', filteredWeightsArray);

   


            
              var c = 0;
              var sum = 0;
              filteredWeightsArray.forEach(e => {
                  c++;
                  sum = sum + e.weight
              });

              avgFoodWeight = (sum / c);
              setAvgTFoodWeight(avgFoodWeight);
              console.log('avgFoodWeight',  avgFoodWeight);
              console.log('avgTFoodWeight', avgTFoodWeight);

              let dateWiseOrder =  filteredWeightsArray
                      .filter(dworder => dworder.UpdateTime.slice(0, 10) === day);
              console.log('datawiseorder', dateWiseOrder, avgOrder, day);

              let breakfast = dateWiseOrder ?. filter (
                      breakfast =>  
              '01' <= breakfast.UpdateTime.slice(11, 13) &&
                      '11' >= breakfast.UpdateTime.slice(11, 13)
              );



              console.log('breakfast', breakfast);
                         
              let launch = dateWiseOrder ?. filter (
                      launch =>
              '11' <= launch.UpdateTime.slice(11, 13) && '18' >= launch.UpdateTime.slice(11, 13)
              );
              console.log('launch', launch);
              let dinner = dateWiseOrder ?. filter (
                      dinner =>
              '18' <= dinner.UpdateTime.slice(11, 13) && '23' >= dinner.UpdateTime.slice(11, 13)
              );
              console.log('dinner', dinner);

            
let bFoodAccepted=breakfast?.filter(order => order.weight >= 200);
var bAcceptedd=bFoodAccepted.length;

let lFoodAccepted=launch?.filter(order => order.weight >= 400);
var lAcceptedd=lFoodAccepted.length;

let dFoodAccepted=dinner?.filter(order => order.weight >= 400);

console.log('dFood', dFoodAccepted.length);
var dAcceptedd=dFoodAccepted.length;

console.log('bAccepted', bAcceptedd);
console.log('lAccepted', lAcceptedd);
console.log('dAccepted', dAcceptedd);



var sumBAcc=0;
bFoodAccepted.forEach(element => {
sumBAcc=sumBAcc+element.weight;
});
console.log('sumBAcc',sumBAcc);

var sumLAcc=0;
lFoodAccepted.forEach(element => {
sumLAcc=sumLAcc+element.weight;
});
console.log('sumLAcc',sumLAcc);



var sumDAcc=0;
dFoodAccepted.forEach(element => {
sumDAcc=sumDAcc+element.weight;
});
console.log('sumDAcc',sumDAcc);





var totalAcceptedd= bAcceptedd + lAcceptedd + dAcceptedd
setTotalAccepted(totalAcceptedd);
console.log('totalAccepted', totalAcceptedd);

const dataToInsert = { officeId: element.DeviceNum,
  weights: filteredWeightsArray,
};
database.ref('offices/' + element.DeviceNum).set(dataToInsert);

}
              });


          
  
          //  }
          });
  
      }
   
  }, [orderList, tent]);
 

 return (
    <>
      
      {/* <p>{tent.capacity}</p> */}
      <div
        className="bg-white p-3 rounded  cursor-pointer "
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {isTitleShow && <h4 className="text-center mb-3">{tent?.name}: اسم المركز</h4>}
        {
        
        /* <div className="flex justify-between items-center">
          <p className="text-xs text-slate-500">Staying</p>
          <p className="text-sm font-semibold">
            <CountUp
              start={0}
              end={
                siteData?.realtime?.staying >= 0
                  ? Math.ceil(siteData?.realtime?.staying)
                  : 0
              }
              duration={2}
              separator=","
            />
          </p>
        </div> */}
        <div className={`text-[${fontSize}]`}>
        <div className="flex flex-row-reverse justify-between items-center ">
          <p className=" text-blue-800 m-[3px]">أعداد الحجاج</p>
          <p className=" font-semibold text-black m-[3px]">
            <CountUp
              start={0}
              end={tent?.capacity ? tent?.capacity : 'Unavailable'}
              duration={2}
              separator=","
            />
            {/* {tent?.capacity} */}
          </p>
        </div>
        <div className="mb-5">
          <p className="flex flex-row-reverse justify-between  m-[3px]">
            <span className="text-blue-800">عدد الوجبات</span>
            <span className="text-black">
              <CountUp
                start={0}
                end={totalAccepted}
                duration={2}
                separator=","
              />
            </span>
          </p>
          <p className=" flex justify-between flex-row-reverse  m-[3px]">
            <span className="text-blue-800">متوسط الوزن</span>
            <span className="text-black">
              <CountUp start={0} end={avgTFoodWeight} duration={2} separator="," />
            </span>
          </p>
        </div>
        </div>
      </div>
    </>
  );
  
};

export default OrderCard;









// {
/* <div className="bg-white p-3 rounded">
  <h4 className="text-center mb-3">{tent.name} مركز</h4>
  <h4 className="text-center mb-3">{tent.capacity} :العدد الفعلي</h4>
  <table className="w-full table-auto text-center text-sm">
    <thead>
      <tr className="text-center">
        <th style={{ padding: '7px' }}> المجموع </th>
        <th style={{ padding: '7px' }}> الوجبات المرفوضة </th>
        <th style={{ padding: '7px' }}> الوجبات المقبولة </th>
        <th style={{ padding: '7px' }}> الوزن المتوسط </th>
        <th style={{ padding: '7px' }}></th>
      </tr>
    </thead>
    <tbody>
      <tr className="text-center">
        <td className="total">
          <CountUp
            start={0}
            end={breakfast.length >= 0 ? Math.ceil(breakfast.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="rejected">
          <CountUp
            start={0}
            end={
              breakfast.length - bAccepted.length >= 0
                ? Math.ceil(breakfast.length - bAccepted.length)
                : 0
            }
            duration={2}
            separator=","
          />
        </td>

        <td className="accepted">
          <CountUp
            start={0}
            end={bAccepted.length >= 0 ? Math.ceil(bAccepted.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="avg_wt">
          <CountUp
            start={0}
            end={bAvgWt >= 0 ? Math.ceil(bAvgWt) : 0}
            duration={2}
            separator=","
          />
          gm
        </td>
        <td>الفطور</td>
      </tr>
      <tr className="text-center">
        <td className="total">
          <CountUp
            start={0}
            end={launch.length >= 0 ? Math.ceil(launch.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="rejected">
          <CountUp
            start={0}
            end={
              launch.length - lAccepted.length >= 0
                ? Math.ceil(launch.length - lAccepted.length)
                : 0
            }
            duration={2}
            separator=","
          />
        </td>
        <td className="accepted">
          <CountUp
            start={0}
            end={lAccepted.length >= 0 ? Math.ceil(lAccepted.length) : 0}
            duration={2}
            separator=","
          />
        </td>

        <td className="avg_wt">
          <CountUp
            start={0}
            end={lAvgWt >= 0 ? Math.ceil(lAvgWt) : 0}
            duration={2}
            separator=","
          />
          gm
        </td>
        <td>الغداء</td>
      </tr>
      <tr className="text-center">
        <td className="total">
          <CountUp
            start={0}
            end={dinner.length >= 0 ? Math.ceil(dinner.length) : 0}
            duration={2}
            separator=","
          />
        </td>
        <td className="rejected">
          <CountUp
            start={0}
            end={
              dinner.length - dAccepted.length >= 0
                ? Math.ceil(dinner.length - dAccepted.length)
                : 0
            }
            duration={2}
            separator=","
          />
        </td>
        <td className="accepted">
          <CountUp
            start={0}
            end={dAccepted.length >= 0 ? Math.ceil(dAccepted.length) : 0}
            duration={2}
            separator=","
          />
        </td>

        <td className="avg_wt">
          <CountUp
            start={0}
            end={dAvgWt >= 0 ? Math.ceil(dAvgWt) : 0}
            duration={2}
            separator=","
          />
          gm
        </td>
        <td>العشاء</td>
      </tr>
      {/* <hr className='w-full border-2'/> */
// }
//       <tr className="text-center mt-2 pt-2 border-t-2 border-black">
//         <td className="total">
//           <CountUp
//             start={0}
//             end={
//               dateWiseOrder.length >= 0 ? Math.ceil(dateWiseOrder.length) : 0
//             }
//             duration={2}
//             separator=","
//           />
//         </td>

//         <td className="rejected">
//           <CountUp
//             start={0}
//             end={
//               dateWiseOrder.length - totalAccepted >= 0
//                 ? Math.ceil(dateWiseOrder.length - totalAccepted)
//                 : 0
//             }
//             duration={2}
//             separator=","
//           />{' '}
//         </td>
//         <td className="accepted">
//           <CountUp
//             start={0}
//             end={totalAccepted >= 0 ? Math.ceil(totalAccepted) : 0}
//             duration={2}
//             separator=","
//           />
//         </td>

//         <td className="avg_wt"> </td>

//         <td>المجموع</td>
//       </tr>
//     </tbody>
//   </table>
// </div>; */}
