import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Card } from '@nextui-org/react';
import {
  Box as MBox,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';

import {
  FaArrowLeft,
  FaArrowRight,
  FaTemperatureLow,
  FaChevronCircleLeft,
  FaChevronLeft,
  FaUsers,
} from 'react-icons/fa';
import SiteMarkedMap from './SiteMarkedMap';
import CountUp from 'react-countup';
import { Loading, Table, Tooltip } from '@nextui-org/react';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
import bus from '../assets/icons/bus.png';
import portalEnter from '../assets/icons/portal-enter.png';
import portalExit from '../assets/icons/portal-exit.png';
import air from '../assets/icons/air-quality-sensor.gif';
import thermometer from '../assets/icons/temperature.gif';
import humidity from '../assets/icons/humidity.gif';
import scale from '../assets/icons/scale.png';
import capacity from '../assets/icons/capacity.png';
import DataTable from './DataTable';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SensorModal, { SensorInfo } from './SensorModal';
import OrderCard from './OrderCard2';
import WeightModal from './WeightModal3';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMaxHumadity,
  setMaximumTemp,
  setMinHumadity,
  setMinimumTemp,
  setSite,
} from '../redux/global';
import { GrGroup } from 'react-icons/gr';
import getEnvironmentData from '../util/getEnvironment';
import formatDateTime from '../util/timeDate';
import { useGetTentListQuery } from '../redux/api';

export default function SiteInfo({ tentList }) {
  const dispatch = useDispatch();
  const site = useSelector(state => state?.global?.site) || {};
  const TentList = useSelector(state => state?.global?.TentList);
  const arafa = useSelector(state => state?.global?.Arafa) || [];
  const arafaPK = arafa?.map(tent => tent?.pk);
  const notArafa = TentList?.filter(tent => arafaPK.indexOf(tent?.pk) === -1);
  const { data: tentData } = useGetTentListQuery(undefined, {
    pollingInterval: 60000,
  });
  // console.log(notArafa);
  const siteData =
    useSelector(
      state => state?.global?.TentData?.filter(tent => tent?.pk === site?.pk)[0]
    ) || {};
  console.log('site ', site, siteData);
  // console.log(siteData);
  const orderList = useSelector(state => state?.global?.OrderList) || [];
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWeight, setIsOpenWeight] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCloseWeight = () => {
    setIsOpenWeight(false);
  };

  let environment = undefined;
  let n = undefined;
  if (siteData?.sensor_data && siteData?.sensor_data?.length > 0) {
    environment = getEnvironmentData(siteData?.sensor_data);
    n = siteData?.sensor_data?.length;
  }
  // console.log(environment);
  const airData = tentData?.filter(tent => tent?.pk === site?.pk)[0];
  console.log('site info', site, tentData, airData?.air_condition);
  const [day, setDay] = useState('');
  const setDate = () => {
    const now = new Date();
    const isoCurrentDate = now.toLocaleDateString('sv', {
      timeZone: 'Asia/Riyadh',
    });
    setDay(isoCurrentDate);
  };
  let totals_in = 0;
  let totals_out = 0;

  const siteData2 = tentList?.filter(i => i.pk === site?.pk)[0];
  totals_in = siteData2?.realtime_data?.totals_in;
  totals_out = siteData2?.realtime_data?.totals_out;

  const staying = totals_in - totals_out > 0 ? totals_in - totals_out : 0;
  const available =
    staying >= 0 ? (siteData2?.capacity - staying) : siteData2?.capacity;
  const sign = available > 0 ? '' : '+';

  return (
    <>
      {isOpenWeight && (
        <WeightModal
          tent={tentList?.filter(i => i.pk === site?.pk)[0]}
          orderList={orderList}
          day={day}
          isOpenWeight={isOpenWeight}
          handleCloseWeight={handleCloseWeight}
        />
      )}
      {isOpen && (
        <SensorModal
          siteData={siteData}
          isOpen={isOpen}
          handleClose={handleClose}
          id={siteData?.pk}
          setIsOpen={setIsOpen}
        />
      )}
      <div className="w-full">
        <h1 className="w-full mb-10 sm:mb-0 text-center text-4xl pt-4 text-[#d68e35]">
          الصفحة الرئيسية
        </h1>
      </div>
      <div className="mb-5 grid place-content-end">
        <MBox sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">المركز</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={JSON.stringify(site)}
              label="sites"
              defaultValue=""
              onChange={e => {
                // console.log(e.target.value);
                dispatch(setSite(JSON.parse(e.target.value)));
                localStorage.setItem('camera', e.target.value);
              }}
              renderValue={site =>
                `${JSON.parse(site)?.name} ${JSON.parse(site)?.location}`
              }
            >
              {/* <MenuItem
                value={JSON.stringify(site)}
                className="py-5 px-4 bg-red-200"
              >
                {site?.name} {site?.location}
              </MenuItem> */}
              {tentList
                .filter(tent => !tent?.is_arafa)
                .map((el, i) => {
                  return (
                    <MenuItem
                      value={JSON.stringify(el)}
                      key={Math.random()}
                      sx={{ py: 2 }}
                    >
                      {el.name} مركز
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </MBox>
      </div>
      <div className="lg:flex justify-between gap-8">
        {/* left side */}
        <div className="basis-full lg:basis-7/12 flex flex-col gap-8 mb-5">
          <Card
            className="col-span-2"
            isPressable
            isHoverable
            variant="bordered"
            css={{ mw: 'auto' }}
          >
            <div
              className="grid grid-cols-3 gap-5 p-5"
              onClick={() => setIsOpen(true)}
            >
              <p className="col-span-3 text-blue-800 text-right text-2xl">
                المؤشرات البيئية
              </p>
              <Card
                // className="col-span-2"
                isPressable
                isHoverable
                variant="bordered"
                css={{ mw: 'auto', p: '$5' }}
              >
                <div className="flex gap-1 items-center justify-end">
                  <p className="text-xl text-blue-800">جودة الهواء</p>
                  <img src={air} className="h-[30px] m-[5px]" alt="" />
                </div>

                <div className="flex gap-1 items-end justify-center">
                  <h2 className="text-xl font-bold text-center">
                    {airData?.air_condition === null ? (
                      <h1 className="text-2xl font-bold">_</h1>
                    ) : airData?.air_condition === 0 ? (
                      'سيء' //'Bad'
                    ) : airData?.air_condition === 1 ? (
                      'متوسط' //'Medium'
                    ) : (
                      'جيد' //'Good'
                    )}
                  </h2>
                </div>
              </Card>

              <Card
                // className="col-span-2"
                isPressable
                isHoverable
                variant="bordered"
                css={{ mw: 'auto', p: '$5' }}
              >
                {environment ? (
                  <div className="w-full  justify-between items-end rounded-lg">
                    <div className="flex justify-end items-center gap-2 grid-cols-2 pb-2">
                      <p className="text-xl text-blue-800"> الرطوبة</p>
                      <img src={humidity} className="h-[30px] m-[2px]" alt="" />
                    </div>
                    <div className="flex gap-1 items-end justify-center">
                      <h2 className="text-3xl font-bold p-[5px]">
                        {environment ? (
                          <>
                            <CountUp
                              start={0}
                              end={environment?.totalHumidity / n}
                              duration={2}
                              separator=","
                            />
                            <span>%</span>
                          </>
                        ) : (
                          '_'
                        )}
                      </h2>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-green-600">
                        <span>
                          {environment
                            ? `${environment?.minHumadity.toPrecision(3)} %`
                            : '.'}
                        </span>
                      </p>
                      <p className="text-orange-600">
                        <span>
                          {environment
                            ? `${environment?.maxHumadity.toPrecision(3)} %`
                            : '.'}
                        </span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="w-full  justify-between items-end rounded-lg">
                    <div className="flex justify-end items-center gap-2 grid-cols-2 pb-2">
                      <p className="text-xl text-blue-800"> الرطوبة</p>
                      <img src={humidity} className="h-[30px] m-[2px]" alt="" />
                    </div>
                    <div className="w-full flex flex-col items-center">
                      <h1 className="text-2xl font-bold">_</h1>
                    </div>
                  </div>
                )}
              </Card>

              <Card
                // className="col-span-2"
                isPressable
                isHoverable
                variant="bordered"
                css={{ mw: 'auto', mh: '250px', p: '$5' }}
              >
                {environment ? (
                  <div className="w-full  justify-between  items-end rounded-lg">
                    <div className="flex gap-1 items-center justify-end">
                      <p className="text-xl text-blue-800"> الحرارة</p>
                      <img
                        src={thermometer}
                        className="h-[30px] m-[5px]"
                        alt=""
                      />
                    </div>

                    <div className="h-auto flex gap-1 items-end justify-center">
                      <h2 className="text-3xl p-[5px] font-bold items-center justify-center">
                        {environment ? (
                          <>
                            <CountUp
                              start={0}
                              end={environment?.totalTemp / n}
                              duration={2}
                              separator=","
                            />
                            <span>°C</span>
                          </>
                        ) : (
                          '_'
                        )}
                      </h2>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-green-600">
                        <span>
                          {environment
                            ? `${environment?.minimumTemp?.toPrecision(3)} °C`
                            : '.'}
                        </span>
                      </p>
                      <p className="text-orange-600">
                        <span>
                          {environment
                            ? `${environment?.maximumTemp.toPrecision(3)} °C`
                            : '.'}
                        </span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="w-full  justify-between items-end rounded-lg">
                    <div className="flex gap-1 items-center justify-end">
                      <p className="text-xl text-blue-800"> الحرارة</p>
                      <img
                        src={thermometer}
                        className="h-[30px] m-[5px]"
                        alt=""
                      />
                    </div>
                    <div className="w-full flex flex-col items-center">
                      <h1 className="text-2xl font-bold">_</h1>
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </Card>
          {/* map */}
          <div className="bg-white p-2 rounded-lg border border-gray-300 hover:shadow-xl">
            <SiteMarkedMap tentList={notArafa} selectedTentId={site?.pk} />
          </div>

          {/* Sitetable */}
          {/* <DataTable tentList={tentList} /> */}
        </div>
        {/* right side */}
        <div className="basis-full lg:basis-5/12 flex flex-col gap-8">
          {/* staying, in, out */}
          {siteData?.realtime && (
            <div className="flex justify-between gap-8 text-right">
              {/* staying */}
              <Card
                isPressable
                isHoverable
                variant="bordered"
                css={{ mw: 'auto', p: '$10' }}
              >
                <div className="flex flex-col gap-3 pb-[30px]">
                  <p className="text-2xl text-blue-800">المتواجدون حاليا</p>
                  <h2 className="text-3xl font-bold">
                    {totals_in - totals_out < 0 ? (
                      <span className="text-3xl font-bold">
                        0
                        {/* (
                        <CountUp
                          start={0}
                          end={
                            //totals_in - totals_out
                            totals_in - totals_out
                            // siteData?.realtime?.staying >= 0
                            //   ? Math.ceil(siteData?.realtime?.staying)
                            //   : 0
                          }
                          duration={2}
                          separator=","
                        />
                        ){' '} */}
                      </span>
                    ) : (
                      <CountUp
                        start={0}
                        end={
                          //totals_in - totals_out
                          totals_in - totals_out >= 0
                            ? totals_in - totals_out
                            : 0
                          // siteData?.realtime?.staying >= 0
                          //   ? Math.ceil(siteData?.realtime?.staying)
                          //   : 0
                        }
                        duration={2}
                        separator=","
                      />
                    )}
                  </h2>
                </div>
                <div className="flex flex-col gap-2 ">
                  {/* <div><img src={bus} className="h-[50px]" alt="bus" /></div> */}
                  <p className="font-bold text-2xl text-black ">
                    {' '}
                    {/* {`${sign}`} */}
                    <CountUp
                      start={0}
                      end={available}
                      duration={2}
                      separator=","
                    />
                  </p>
                  <p className="text-2xl text-blue-800">المتبقي</p>
                </div>
              </Card>
              <Card
                isPressable
                isHoverable
                variant="bordered"
                css={{ mw: 'auto', p: '$10' }}
              >
                {' '}
                <div className="w-full justify-between items-start ">
                  <div className="flex gap-3 items-center justify-end">
                    <p className="text-2xl text-blue-800">أعداد الحجاج</p>
                    <GrGroup className="text-3xl" />
                  </div>

                  <div className="w-full h-32 grid text-black justify-center items-center text-3xl font-bold">
                    <CountUp
                      start={0}
                      end={
                        // siteData?.realtime?.totals_in || 0
                        siteData2?.capacity
                      }
                      duration={2}
                      separator=","
                    />
                  </div>
                </div>
              </Card>
            </div>
          )}
          {/* Gate Table */}

          {/* Sensor Table */}

          {/* staying, in, out */}
          {siteData?.realtime && (
            <div className="items-center grid grid-cols-2 justify-between gap-8 text-right">
              {/* {isOpenWeight && (
                <WeightModal
                  tent={tentList?.filter(i => i.pk === site?.pk)[0]}
                  orderList={orderList}
                  day={day}
                  isOpenWeight={isOpenWeight}
                  handleCloseWeight={handleCloseWeight}
                />
              )} */}
              <Card
                className="col-span-2"
                isPressable
                isHoverable
                variant="bordered"
                css={{ mw: 'auto' }}
                onClick={() => setIsOpenWeight(true)}
              >
                {/* staying */}
                {/* <p className="text-[26px]">اسم المركز</p> */}
                <div className="w-full justify-between p-5 text-violet-600 items-end">
                  <div className="flex gap-3 items-center justify-end">
                    <p className="text-2xl text-blue-800">الوجبات</p>
                    <img src={scale} className="h-9" alt="" />
                  </div>
                  <div className="">
                    <OrderCard
                      tent={tentList?.filter(i => i.pk === site?.pk)[0]}
                      orderList={orderList}
                      day={day}
                      isTitleShow={false}
                      fontSize="20px"
                    ></OrderCard>
                  </div>
                </div>
              </Card>

              {/* in, out */}

              {/* {isOpen && (
                <SensorModal
                  siteData={siteData}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  id={siteData?.pk}
                  setIsOpen={setIsOpen}
                />
              )} */}

              {/* temp card */}
            </div>
          )}
          {/* {isOpen && (
            <SensorModal
              siteData={siteData}
              isOpen={isOpen}
              handleClose={handleClose}
              id={siteData?.pk}
              setIsOpen={setIsOpen}
            />
          )}

          <div
            onClick={() => setIsOpen(true)}
            className="bg-white p-5 text-2xl cursor-pointer rounded"
          >
            <h4>Avg Temp: {avg_temp}&deg;C</h4>
            <h4>Avg Hum: {avg_hum}%</h4>
          </div> */}
          {/* <div className="w-full bg-white border hover:shadow-xl border-gray-300 rounded-2xl flex flex-col gap-3 justify-center items-center py-5">
            <div className="w-full flex text-blue-800 flex-row-reverse justify-around mb-1">
              <p className="font-medium text-xl "> البوابة </p>
              <p className="font-medium text-xl">الدخول</p>
              <p className="font-medium text-xl">الخروج</p>
              <p className="font-medium text-xl"> الحالة</p>
            </div>
            {siteData?.cameras_data?.length > 0 ? (
              <Flex
                flexFlow={'column'}
                w="100%"
                mx="auto"
                pb={3}
                maxHeight={'200px'}
                overflowY={'auto'}
                className="scrolling"
              >
                {siteData?.cameras_data.slice(0, 8).map((el, i) => (
                  <Box key={i} my={1}>
                    <SimpleGrid columns={2}>
                      <Flex
                        flexFlow={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        {el.last_update
                          ? formatDateTime(el.last_update)
                          : 'Unavailable'}
                      </Flex>
                      <Flex
                        flexFlow={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Text fontWeight={'bold'} className="text-2xl">
                          {el.totals_out ? el.totals_out : 0}
                        </Text>
                      </Flex>
                      <Flex
                        flexFlow={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Text fontWeight={'bold'} className="text-2xl">
                          {el.totals_in ? el.totals_in : 0}
                        </Text>
                      </Flex>
                      <Flex
                        flexFlow={'column'}
                        justifyContent={'end'}
                        alignItems={'center'}
                      >
                        <p className="font-medium text-xl">
                          <span auto flat>
                            {i + 1}
                          </span>
                        </p>
                      </Flex>
                    </SimpleGrid>
                  </Box>
                ))}
              </Flex>
            ) : (
              <Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
                No Camera Assigned
              </Typography>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}
