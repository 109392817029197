import React, { useState, useEffect } from 'react';
import { Table } from '@nextui-org/react';
import CountUp from 'react-countup';
import close from '../assets/icons/close.png';
import ReactPaginate from 'react-paginate';

const model_styles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1500,
  borderRadius: '12px',
  height: '400px',
  width: '700px',
};

const overlay_style = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: '#D9D9D980',
  zIndex: 1000,
};

const nested_model_styles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1600,
  borderRadius: '12px',
  height: '600px',
  width: '800px',
};

const getMealKey = mealName => {
  switch (mealName) {
    case 'الفطور':
      return 'breakfast';
    case 'الغداء':
      return 'lunch';
    case 'العشاء':
      return 'dinner';
    default:
      return '';
  }
};

const NestedModal = ({ handleClose, mealName, tentPk, tentDate }) => {
  const [mealData, setMealData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const mealKey = getMealKey(mealName);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://baitguests2.com:8000/api/camera/food_weight/summary/${tentDate}/${tentPk}/${mealKey}`,
          {
            headers: {
              Authorization: 'Token bc9d81174fc895b0618460b8ffc9350f806883df',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setMealData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [mealName, tentPk, tentDate, mealKey]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const renderTable = weights => {
    const offset = currentPage * itemsPerPage;
    const currentPageData = weights.slice(offset, offset + itemsPerPage);

    const tableRows = [];
    for (let i = 0; i < currentPageData.length; i += 5) {
      const row = currentPageData.slice(i, i + 5);
      tableRows.push(row);
    }

    return (
      <table>
        <tbody>
          {tableRows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((weight, cellIndex) => (
                <td key={cellIndex}>{weight}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  if (!mealData) {
    return <div>Loading...</div>;
  }

  const acceptedWeights = mealData.accepted_weights || [];
  const rejectedWeights = mealData.rejected_weights || [];

  const acceptedPageCount = Math.ceil(acceptedWeights.length / itemsPerPage);
  const rejectedPageCount = Math.ceil(rejectedWeights.length / itemsPerPage);

  return (
    <>
      <div onClick={handleClose} style={overlay_style} />
      <div
        style={nested_model_styles}
        className="flex md:px-[300px] px-[150px] relative justify-center items-center"
      >
        <div className="absolute w-full h-full justify-center items-center">
          <div className="bg-white p-3 relative rounded-3xl">
            <div
              className="absolute top-0 right-0 p-2 cursor-pointer"
              onClick={handleClose}
            >
              <img src={close} className="h-[30px] m-[10px]" alt="Close" />
            </div>
            <h4 className="text-center mb-3 text-blue-800 text-[18px]">
              {mealName}
            </h4>
            <h4 className="text-center mb-3 text-blue-800 text-[18px]">
              Tent PK: {tentPk}
            </h4>
            <h4 className="text-center mb-3 text-blue-800 text-[18px]">
              Tent Date: {tentDate}
            </h4>
            <div>
              <h5>Accepted Weights</h5>
              {renderTable(acceptedWeights)}
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={acceptedPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
            <div>
              <h5>Rejected Weights</h5>
              {renderTable(rejectedWeights)}
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={rejectedPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const WeightModal = ({ isOpen, handleClose, tent }) => {
  const [isNestedOpen, setIsNestedOpen] = useState(false);
  const [selectedMealName, setSelectedMealName] = useState('');
  const [selectedTentPk, setSelectedTentPk] = useState('');
  const [selectedTentDate, setSelectedTentDate] = useState('');

  const handleNestedOpen = (mealName, tentPk, tentDate) => {
    setSelectedMealName(mealName);
    setSelectedTentPk(tentPk);
    setSelectedTentDate(tentDate);
    setIsNestedOpen(true);
  };

  const handleNestedClose = () => {
    setIsNestedOpen(false);
    setSelectedMealName('');
    setSelectedTentPk('');
    setSelectedTentDate('');
  };

  if (!tent) {
    return null;
  }

  return (
    <>
      {isOpen ? (
        <>
          <div onClick={handleClose} style={overlay_style} />
          <div
            style={model_styles}
            className="flex md:px-[300px] px-[150px] relative justify-center items-center"
          >
            <div className="absolute w-full h-full justify-center items-center">
              <div className="bg-white p-3 relative rounded-3xl">
                <div
                  className="absolute top-0 right-0 p-2 cursor-pointer"
                  onClick={handleClose}
                >
                  <img src={close} className="h-[30px] m-[10px]" alt="Close" />
                </div>
                <h4 className="text-center mb-3 text-blue-800 text-[18px]">
                  {tent.name} مركز
                </h4>
                <h4 className="text-center mb-3 text-blue-800 ">
                  {tent.capacity} : اعداد الحجاج
                </h4>
                <Table
                  lined
                  aria-label="Weight data table"
                  css={{ height: 'auto', minWidth: '100%' }}
                >
                  <Table.Header>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوجبات المرفوضة{' '}
                    </Table.Column>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوجبات المقبولة{' '}
                    </Table.Column>
                    <Table.Column className="text-blue-800 text-sm">
                      {' '}
                      الوزن المتوسط{' '}
                    </Table.Column>
                    <Table.Column></Table.Column>
                  </Table.Header>
                  <Table.Body>
                    {['breakfast', 'lunch', 'dinner'].map((meal, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <CountUp
                            start={0}
                            end={tent.meals[meal].rejectedMeals}
                            duration={2}
                            separator=","
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <CountUp
                            start={0}
                            end={tent.meals[meal].acceptedMeals}
                            duration={2}
                            separator=","
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <CountUp
                            start={0}
                            end={tent.meals[meal].averageWeight}
                            duration={2}
                            separator=","
                          />{' '}
                          gm
                        </Table.Cell>
                        <Table.Cell
                          css={{ color: '#1d4ed8', cursor: 'pointer' }}
                        >
                          <button onClick={() => handleNestedOpen(tent.meals[meal].name, tent.id, tent.date)} >{tent.meals[meal].name}</button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    <Table.Row key="4">
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            tent.meals.breakfast.rejectedMeals +
                            tent.meals.lunch.rejectedMeals +
                            tent.meals.dinner.rejectedMeals
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <CountUp
                          start={0}
                          end={
                            tent.meals.breakfast.acceptedMeals +
                            tent.meals.lunch.acceptedMeals +
                            tent.meals.dinner.acceptedMeals
                          }
                          duration={2}
                          separator=","
                        />
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell css={{ color: '#1d4ed8' }}>
                        المجموع
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>
          {isNestedOpen && (
            <NestedModal handleClose={handleNestedClose} mealName={selectedMealName} tentPk={selectedTentPk} tentDate={selectedTentDate} />
          )}
        </>
      ) : null}
    </>
  );
};

export default WeightModal;
