import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Link as ChakraLink,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import logo from '../../assets/logo.png';

import {
  AiFillCamera,
  AiOutlineCamera,
  AiOutlineUserAdd,
} from 'react-icons/ai';
import { HiUserAdd } from 'react-icons/hi';
import { FaHome } from 'react-icons/fa';
import { RiFullscreenFill, RiHome4Fill, RiHome4Line } from 'react-icons/ri';
import { RiSensorFill, RiSensorLine } from 'react-icons/ri';

import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function AdminLayout({ children }) {
  const [path, setPath] = useState('');
  const location = useLocation();
  useEffect(() => {
    setPath(location.pathname.split('/')[2]);
  }, [location]);

  const heads = [
    {
      path: 'userregistration',
      name: 'User Registration',
      iconActive: HiUserAdd,
      iconInactive: AiOutlineUserAdd,
    },
    {
      path: 'siteregistration',
      name: 'Site Registration',
      iconActive: RiHome4Fill,
      iconInactive: RiHome4Line,
    },
    {
      path: 'cameraregistration',
      name: 'Camera Registration',
      iconActive: AiFillCamera,
      iconInactive: AiOutlineCamera,
    },
    {
      path: 'sensorregistration',
      name: 'Sensor Registration',
      iconActive: RiSensorFill,
      iconInactive: RiSensorLine,
    },
  ];
  const handleFullScreenToggle = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  };

  const navigate = useNavigate();
  return (
    <Flex
      // w={'100vw'}
      // mx="auto"
      h="100vh"
      flexFlow={'column'}
      // border="2px solid"
      // borderColor={'highlighter.500'}
    >
      <Flex
        // w="100%"
        h="15vh"
        justifyContent={'space-between'}
        alignItems="center"
        boxShadow="lg"
        bg="#F7F7F7"
      >
        <Image
          src={logo}
          // w="100%"
          h="100%"
          ml={10}
          py={2}
          cursor={'pointer'}
          onClick={() => {
            navigate('/home');
          }}
        />
        <div className="w-full mx-5 flex gap-8 items-center">
          <button
            className="cursor-pointer"
            onClick={() => {
              handleFullScreenToggle();
            }}
          >
            <RiFullscreenFill size={30} />
          </button>

          {/* <button>
            <h1>Hide Nav</h1>
          </button> */}
        </div>
      </Flex>
      <Flex>
        <Flex flexFlow={'column'} width={'25%'} boxShadow={'lg'} pt={20}>
          <ChakraLink
            className="flat" key={Math.random()}
            as={Link}
            py={3}
            to={`/home`}
            bg={'transparent'}
            color={'text.light'}
            _hover={{ color: 'text.dark' }}>
            <Flex alignItems={'center'} justifyContent={'start'} pl={5}>
              <Icon as={FaHome} h={8} w={8} mr={3} />
              <Text fontWeight={'semibold'} fontSize={'lg'}>
                Back to Home
              </Text>
            </Flex>
          </ChakraLink>
          {heads.map((el, i) =>
            el.type === 'accor' ? (
              <Accordion allowMultiple borderColor={'bg.light'}>
                <AccordionItem>
                  <AccordionButton>
                    <Flex
                      className="flat"
                      key={Math.random()}
                      py={3}
                      bg={'transparent'}
                      color={'ash.500'}
                      justifyContent={'space-around'}
                      width={'full'}
                    >
                      <Text fontWeight={'semibold'} fontSize={'lg'}>
                        {el.name}
                      </Text>
                      <AccordionIcon h={6} w={6} />
                    </Flex>
                  </AccordionButton>
                  <AccordionPanel borderBottom={'1px'} borderColor={'ash.600'}>
                    {el.items.map((e, i) => (
                      <ChakraLink
                        className="flat"
                        key={Math.random()}
                        as={Link}
                        py={3}
                        to={`/adminsite/${e.path}`}
                        bg={'transparent'}
                        borderBottomWidth={'3px'}
                        borderBottomColor={
                          e.path === path ? 'border.light' : 'transparent'
                        }
                        color={e.path === path ? 'text.light' : 'ash.500'}
                        _hover={e.path === path ? '' : { color: 'ash.300' }}
                      >
                        <Flex
                          alignItems={'center'}
                          justifyContent={'start'}
                          pl={3}
                        >
                          <Icon
                            as={e.path === path ? e.iconActive : e.iconInactive}
                            h={8}
                            w={8}
                            mr={3}
                          />
                          <Text fontWeight={'semibold'} fontSize={'lg'}>
                            {e.name}
                          </Text>
                        </Flex>
                      </ChakraLink>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ) : (
              <ChakraLink
                className="flat"
                key={Math.random()}
                as={Link}
                py={3}
                to={`/adminsite/${el.path}`}
                bg={'transparent'}
                borderBottomWidth={'3px'}
                borderBottomColor={
                  el.path === path ? 'text.dark' : 'transparent'
                }
                color={el.path === path ? 'text.dark' : 'text.light'}
                _hover={el.path === path ? '' : { color: 'ash.300' }}
              >
                <Flex alignItems={'center'} justifyContent={'start'} pl={3}>
                  <Icon
                    as={el.path === path ? el.iconActive : el.iconInactive}
                    h={8}
                    w={8}
                    mr={3}
                  />
                  <Text fontWeight={'semibold'} fontSize={'lg'}>
                    {el.name}
                  </Text>
                </Flex>
              </ChakraLink>
            )
          )}
        </Flex>
        <Flex
          flexFlow={'column'}
          className="scrolling"
          w="full"
          h="full"
          px={3}
          pt={3}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}
